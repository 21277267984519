import React, {useState, useEffect} from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import ProfilDefault from "../../images/profil_default.jpg"
import api from '../../api/configApi.js';
import "../InfoSection/testimonials.css";

import {
    InfoContainer, 
    InfoWrapper, 
    InfoRow, 
    Column1, 
    Column2, 
    TextWrapper, 
    TopLine, 
    Heading, 
    Subtitle, 
    BtnWrap,
    BtnLink,
    ImgWrap,
    Img
} from './InfoElements';

const InfoSection = ({
    lightBg, 
    id, 
    imgStart, 
    topLine, 
    lightText, 
    headline,
    darkText,
    description,
    buttonLabel,
    img,
    alt
}) => {
  const [testimonials, setTestimonials] = useState([]);
  
  useEffect(() => {
    const fetchData = async () => {
      try {

        const response = await api.get(`/testimonialsPublic`);
        setTestimonials(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <InfoContainer lightBg={lightBg} id={id}>
        <InfoWrapper>
            <InfoRow imgStart={imgStart}>
                <Column1>
                <TextWrapper>
                    <TopLine>{topLine}</TopLine>
                    <Heading lightText={lightText}>{headline}</Heading>
                    <Subtitle darkText={darkText}>{description}</Subtitle>
                    <BtnWrap>
                        <BtnLink to='/signupForm' 
                        >{buttonLabel}</BtnLink>
                    </BtnWrap>
                </TextWrapper>
                </Column1>
                <Column2>
                    <ImgWrap>
                        <Img src={img} alt={alt} />
                    </ImgWrap>
                </Column2>
            </InfoRow>

        </InfoWrapper>
        <InfoWrapper>
        <Carousel
              showArrows={true}
              infiniteLoop={true}
              showThumbs={false}
              showStatus={false}
              autoPlay={true}
              interval={5100}
            >
              {testimonials.map((testimonial, index) => (
                <div key={testimonial.id}>
                  {testimonial && testimonial.member.url !== null && 
                  
                  <img src={testimonial.member.url} alt="testimonial"/>
                  }
                  {testimonial && testimonial.member.url === null && 
                  
                  <img src={ProfilDefault} alt="profile pict" />
                  }
                  <div className="myCarousel" style={{paddingTop: '10%'}}>
                    <h3>{testimonial.member.firstName + ' ' + testimonial.member.lastName}</h3>
                    <h4>{testimonial.member.occupation.occupationName}</h4>
                    <p>
                      {testimonial.testimonial}
                    </p>
                  </div>
                </div>
                ))
              }
            </Carousel>
        </InfoWrapper>
      </InfoContainer>
    </>
  )
}

export default InfoSection
