import React from 'react';
import Video from '../../videos/video.mp4';
import {
    FaInstagram,
    FaYoutube,
} from 'react-icons/fa';
import {
    HeroContainer,
    HeroBg,
    VideoBg,
    HeroContent,
    HeroH1,
    HeroP,
    SocialMedia,
    SocialMediaWrap,
    SocialIcons,
    SocialIconLink
} from './HeroElements';

const HeroSection = () => {
  return (
    <HeroContainer id="home">
        <HeroBg>
            <VideoBg autoPlay loop muted src={Video} type='video/mp4' />
        </HeroBg>
        <HeroContent>
            <HeroH1>We Are To Help You Be Smartest English Conversation</HeroH1>
            <HeroP>
                You can see our social media.
            </HeroP>
            <SocialMedia>
                <SocialMediaWrap>
                    <SocialIcons>
                        <SocialIconLink href="https://instagram.com/sefeo.english" target="_blank" aria-label="Instagram">
                            <FaInstagram size={60} />
                        </SocialIconLink>
                        <SocialIconLink href="https://youtube.com/@sefeo" target="_blank" aria-label="Youtube">
                            <FaYoutube size={60} />
                        </SocialIconLink>
                    </SocialIcons>
                </SocialMediaWrap>
            </SocialMedia>
        </HeroContent>
    </HeroContainer>
  );
}

export default HeroSection
