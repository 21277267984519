import React, {useState, useEffect} from 'react';
import {FaBars} from 'react-icons/fa';
import {IconContext} from 'react-icons/lib';
import { animateScroll as scroll } from 'react-scroll';
import { 
    Nav, 
    NavbarContainer, 
    NavLogo, 
    MobileIcon, 
    NavMenu, 
    NavItem, 
    NavLinks,
    NavBtn,
    NavBtnLink
 } from './NavbarElements';
 import sefeoLogo from '../../images/SEFEO-LOGO.png';

const Navbar = ({ toggle }) => {
    const [scrollNav, setScrollNav] = useState(false);

    const changeNav = () => {
        if(window.scrollY >= 80){
            setScrollNav(true);
        }else{
            setScrollNav(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', changeNav);
    }, []);

    const toggleHome = () => {
        scroll.scrollToTop();
    };
    
  return (
    <>
    <IconContext.Provider value={{ color: '#fff'}}>
      <Nav scrollNav={scrollNav}>
        <NavbarContainer>
            <NavLogo to='/' onClick={toggleHome}>
                <img
                    src={sefeoLogo}
                    width='110px'
                    style={{
                    maxWidth: '80%',
                    height: 'auto',
                    }}
                    alt='sefeo logo navbar'
                />
            </NavLogo>
            <MobileIcon onClick={toggle}>
                <FaBars />
            </MobileIcon>
            <NavMenu>
                <NavItem>
                    <NavLinks to='about' smooth={true} duration={500} spy={true} exact='true' offset={-80} >About</NavLinks>
                </NavItem>
                <NavItem>
                    <NavLinks to='discover' smooth={true} duration={500} spy={true} exact='true' offset={-80} >Rules</NavLinks>
                </NavItem>
                <NavItem>
                    <NavLinks to='sefeoteam' smooth={true} duration={500} spy={true} exact='true' offset={-80} >Sefeo Team</NavLinks>
                </NavItem>
                <NavItem>
                    <NavLinks to='signup' smooth={true} duration={500} spy={true} exact='true' offset={-80} >Sign Up</NavLinks>
                </NavItem>
            </NavMenu>
            <NavBtn>
                <NavBtnLink to="/signin">Sign In</NavBtnLink>
            </NavBtn>
        </NavbarContainer>
      </Nav>
      </IconContext.Provider>
    </>
  )
}

export default Navbar;
