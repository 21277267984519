import React from 'react';
import 'react-toastify/dist/ReactToastify.css';


const ErrorHandling = () => {

  return (
    <div class="bg-white" style={{ minHeight: '932px', bottom:0, left:0, top:0, right:0, zIndex: 0, overflow: 'hidden'}}>
      <div className="offset-lg-3 col-lg-6 pt-5 pb-5">
        <div class="page-wrap d-flex flex-row align-items-center">
            <div class="container">
              <div className="card-body">
                  <div class="row justify-content-center">
                      <div class="col-md-12 text-center">
                          <span class="display-1 d-block">404</span>
                          <div class="mb-4 lead">Page Not Found.</div>
                          <a href="/" class="btn btn-link">Back To Home</a>
                      </div>
                  </div>
              </div>
            </div>
        </div>
      </div>
    </div>
  )
}

export default ErrorHandling;
