import React from 'react';
import {
    AboutContainer, 
    AboutWrapper, 
    AboutRow, 
    Column1, 
    Column2, 
    TextWrapper, 
    TopLine, 
    Heading, 
    Subtitle, 
    ImgWrap,
    Img
} from './AboutElements';

const AboutSection = ({
    lightBg, 
    id, 
    imgStart, 
    lightText, 
    headline,
    darkText,
    description,
    img,
    alt
}) => {
  return (
    <>
      <AboutContainer lightBg={lightBg} id={id}>
        <AboutWrapper>
            <AboutRow imgStart={imgStart}>
                <Column1>
                <TextWrapper>
                    <TopLine>About SEFEO</TopLine>
                    <Heading lightText={lightText}>{headline}</Heading>
                    <Subtitle darkText={darkText}>{description}</Subtitle>
                </TextWrapper>
                </Column1>
                <Column2>
                    <ImgWrap>
                        <Img src={img} alt={alt} />
                    </ImgWrap>
                </Column2>
            </AboutRow>
        </AboutWrapper>
      </AboutContainer>
    </>
  )
}

export default AboutSection
