
export const homeObjTwo = {
    id: 'discover',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: "Discussion Rules ",
    headline: 'Discussion Rules of SEFEO English Community',
    description: 'We are learning as we go and grow. Every day this community accepts new members, and every member has slightly different needs. Meanwhile, please be patient and work with us to deliver a better experience We reserve the right to block any disruptive and/or rule-breaking member from the community without notice.',
    li1: 'No harassment of any religion, country, people, etc',
    li2: 'Respect is a must',
    li3: 'English is our main language here',
    imgStart: false,
    img: require('../../images/educator_re.png'),
    alt: 'Education',
    dark: true,
    primary: true,
    darkText: true
};