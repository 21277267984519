

export const homeObjThree = {
    id: 'signup',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: "Join our Team",
    headline: 'Creating an member is extremly easy ',
    description: 'Get everything set up and ready in under 10 minutes. All you need to do is add your information and you are ready to go',
    buttonLabel: 'Sign up Now',
    imgStart: false,
    img: require('../../images/empty_cart.png'),
    alt: 'Education',
    dark: false,
    primary: false,
    darkText: true
};