import React, { useState } from 'react';
import Footer from '../components/Footer';
import HeroSection from '../components/HeroSection';
import InfoSection from '../components/InfoSection';
import { homeObjThree } from '../components/InfoSection/Data';
import Navbar from '../components/Navbar';
import SefeoTeam from '../components/SefeoTeam';
import Sidebar from '../components/Sidebar';
import { homeObjOne } from '../components/AboutSection/Data';
import { homeObjTwo } from '../components/RulesSection/Data';
import AboutSection from '../components/AboutSection';
import RulesSection from '../components/RulesSection';

const Home = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen);
    };
    
  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <HeroSection />
      <AboutSection {...homeObjOne} />
      <RulesSection {...homeObjTwo} />
      <SefeoTeam /> 
      <InfoSection {...homeObjThree} />
      <Footer />
    </>
  );
}

export default Home
