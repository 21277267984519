import React from 'react';
import './App.css';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Home from './pages';
import SigninPage from './pages/signin';
import SignupPage from './pages/signup';
import ErrorHandlingPage from './pages/errorHandlingPage';

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/signin' element={<SigninPage />} />
        <Route path='/signupForm' element={<SignupPage />} />
        <Route path='/errorHandlingPage' element={<ErrorHandlingPage />} />
      </Routes>
    </Router>
  );
}

export default App;
