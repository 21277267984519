import React from 'react';
import { animateScroll as scroll } from 'react-scroll';
import {
    FaInstagram,
    FaYoutube
} from 'react-icons/fa';
import {
    FooterContainer,
    FooterWrap,
    FooterLinksContainer,
    FooterLinksWrapper,
    SocialMedia,
    SocialMediaWrap,
    SocialLogo, 
    WebsiteRights,
    SocialIcons,
    SocialIconLink
} from './FooterElements';
import sefeoLogo from '../../images/SEFEO-LOGO.png';

const Footer = () => {
    const toggleHome = () => {
        scroll.scrollToTop();
    };
  return (
    <FooterContainer>
        <FooterWrap>
            <FooterLinksContainer>
                <FooterLinksWrapper>
                    <FooterLinksWrapper>
                    </FooterLinksWrapper>
                </FooterLinksWrapper>
            </FooterLinksContainer>
            <SocialMedia>
                <SocialMediaWrap>
                    <SocialLogo to='/' onClick={toggleHome}>
                        <img
                            src={sefeoLogo}
                            width='110px'
                            style={{
                            maxWidth: '80%',
                            height: 'auto',
                            }}
                            alt='sefeo logo footer'
                        />
                    </SocialLogo>
                    <WebsiteRights>SEFEO IT Developer © {new Date().getFullYear()} All rights reserved. </WebsiteRights>
                    <SocialIcons>
                        <SocialIconLink href="https://instagram.com/sefeo.english" target="_blank" aria-label="Instagram">
                            <FaInstagram />
                        </SocialIconLink>
                        <SocialIconLink href="https://youtube.com/@sefeo" target="_blank" aria-label="Youtube">
                            <FaYoutube />
                        </SocialIconLink>
                    </SocialIcons>
                </SocialMediaWrap>
            </SocialMedia>
        </FooterWrap>
    </FooterContainer>
      
  )
}

export default Footer
