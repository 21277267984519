export const homeObjOne = {
    id: 'about',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: "About SEFEO",
    headline: 'SEFEO English Community',
    description: 'SEFEO is an Online English Community aimed at Speaking Practice. This community was created by Zelky Yusuf on October 31st, 2012 and recreated on October 31st, 2021. The intention of this community is to help you improve your speaking abilities, connect with each other, help bring a greater discussion and this is a place for you to gather, connect, share your thoughts and build your positive characters.',
    imgStart: false,
    img: require('../../images/content_team.png'),
    alt: 'Education',
    dark: true,
    primary: true,
    darkText: false
};