import React from 'react';

import {
    RulesContainer, 
    RulesWrapper, 
    RulesRow, 
    Column1, 
    Column2, 
    TextWrapper, 
    TopLine, 
    Heading,
    Heading2, 
    Subtitle,
    UlRules,
    LiRules, 
    ImgWrap,
    Img
} from './RulesElements';

const RulesSection = ({
    lightBg, 
    id, 
    imgStart, 
    topLine, 
    lightText, 
    headline,
    darkText,
    li1,
    li2,
    li3,
    description,
    img,
    alt
}) => {
  return (
    <>
      <RulesContainer lightBg={lightBg} id={id}>
        <RulesWrapper>
            <RulesRow imgStart={imgStart}>
                <Column1>
                <TextWrapper>
                    <TopLine>{topLine}</TopLine>
                    <Heading lightText={lightText}>{headline}</Heading>
                    <UlRules>
                        <LiRules>{li1}</LiRules>
                        <LiRules>{li2}</LiRules>
                        <LiRules>{li3}</LiRules>
                    </UlRules>
                </TextWrapper>
                <TextWrapper>
                    <Heading2 lightText={lightText}>NOTE</Heading2>
                    <Subtitle darkText={darkText}>{description}</Subtitle>
                </TextWrapper>
                </Column1>
                <Column2>
                    <ImgWrap>
                        <Img src={img} alt={alt} />
                    </ImgWrap>
                </Column2>
            </RulesRow>
        </RulesWrapper>
      </RulesContainer>
    </>
  )
}

export default RulesSection
