import React from 'react';
import ScrollToTop from '../components/ScrollToTop';
import ErrorHandling from '../components/ErrorHandling';

const ErrorHandlingPage = () => {
  return (
    <>
      <ScrollToTop />
      <ErrorHandling />
    </>
  );
};

export default ErrorHandlingPage;
