import styled from 'styled-components';

export const SefeoTeamContainer = styled.div`
    height: 1200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #010606;

    @media screen and (max-width: 768px){
        height: 2200px;
    }

    @media screen and (max-width: 480px){
        height: 2000px;
    }
`;

export const SefeoPresidentWrapper = styled.div`
    max-width: 460px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 19px;
    padding: 0 0px;
    margin-bottom: 40px;
    align-items: center;

    @media screen and (max-width:1024px){
        grid-template-columns: 1fr;
    }

    @media screen and (max-width: 768px){
        grid-template-columns: 1fr;
        padding: 0 20px;
    }
`;

export const SefeoTeamWrapper = styled.div`
    max-width: 1000px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    align-items: center;
    grid-gap: 19px;
    padding: 0 0px;

    @media screen and (max-width:1024px){
        grid-template-columns: 1fr 1fr;
    }

    @media screen and (max-width: 768px){
        grid-template-columns: 1fr;
        padding: 0 20px;
    }
`;

export const SefeoTeamCard = styled.div`
    background: #D6B495;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: 10px;
    max-width: 250px;
    max-height: 380px;
    padding: 30px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.2);
    transition: all 0.2s ease-in-out;

    &:hover{
        transform: scale(1.02);
        transition: all 0.2s ease-in-out;
        cursor: pointer;
    }

`;

export const SefeoTeamIcon = styled.img`
    height: 220px;
    width: 180px;
    margin-bottom: 8px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.2);
    border-radius: 10px 10px 10px 10px;

    @media screen and (max-width:1024px){
        height: 180px;
        width: 140px;
    }
`;

export const SefeoTeamH1 = styled.h1`
    font-size: 2.5rem;
    color: #fff;
    margin-bottom: 64px;

    @media screen and (max-width: 480px){
        font-size:  2rem;
    }
`;

export const SefeoTeamH2 = styled.h2`
    font-size: 1.5rem;
    margin-bottom: 10px;
    text-align: center;
    color: #fff;
`;

export const SefeoTeamP = styled.p`
    font-size: 1.0rem;
    text-align: center;
    color: #fff;
`;