 import React, {useState, useEffect} from "react";
 import api from '../../api/configApi.js';

 import {
    SefeoTeamContainer,
    SefeoTeamH1,
    SefeoPresidentWrapper,
    SefeoTeamWrapper,
    SefeoTeamCard,
    SefeoTeamIcon,
    SefeoTeamH2,
    SefeoTeamP
 } from './SefeoTeamElements';
 
 const SefeoTeam = () => {
    const [teams, setTeam] = useState([]);
    // Use the environment variable for the base URL

    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await api.get(`/teamsPublic`);
            setTeam(response.data);
          } catch (error) {
            console.error(error);
          }
        };
    
        fetchData();
      }, []);

   return (
     <SefeoTeamContainer id="sefeoteam">
        <SefeoTeamH1>Our Team</SefeoTeamH1>
        <SefeoPresidentWrapper>
        {teams.map((team, index) => (
            team && team.id === 1 && (
                
                  <SefeoTeamCard key={team.id}>
                      <SefeoTeamIcon src={team.url} />
                      <SefeoTeamH2>{team.member.firstName + ' ' + team.member.lastName}</SefeoTeamH2>
                      <SefeoTeamP>{team.position}</SefeoTeamP>
                  </SefeoTeamCard>
              )
            )
          )
        }
        </SefeoPresidentWrapper>
        <SefeoTeamWrapper>
        {teams.map((team, index) => (
              team && team.id !== 1 && (
                    <SefeoTeamCard key={team.id}>
                        <SefeoTeamIcon src={team.url} />
                        <SefeoTeamH2>{team.member.firstName + ' ' + team.member.lastName}</SefeoTeamH2>
                        <SefeoTeamP>{team.position}</SefeoTeamP>
                    </SefeoTeamCard>
              )
            )
          )
        }
        </SefeoTeamWrapper>
     </SefeoTeamContainer> 
   )
 }
 
 export default SefeoTeam
 